import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import Pdf from "../Resources/resume.pdf";

import styles from "./SocialBar.module.css";

export default function SocialBar() {
  return (
    <Container
      className="centered-container"
      style={{ backgroundColor: "", marginTop: "2rem" }}
    >
      <Row>
        <Col
          // style={{backgroundColor: "blue"}}
        >
          <a
            href="https://www.linkedin.com/in/henryliu8/"
            target="_blank"
            rel="noopener noreferrer"
            align="center"
          >
            <LinkedInIcon
              style={{ width: 40, height: 40 }}
              className={styles.link}
            />
          </a>
        </Col>
        <Col
          // style={{backgroundColor: "green"}}
        >
          <a
            href={Pdf}
            target="_blank"
            rel="noopener noreferrer"
            align="center"
          >
            <p style={{ fontSize: "1.3rem", marginTop: "0.3rem" }} className={styles.link}>
              Resume
            </p>
          </a>
        </Col>
        <Col>
          <a
            href="https://github.com/henryliu5"
            target="_blank"
            rel="noopener noreferrer"
            align="center"
          >
            <GitHubIcon
              style={{ width: 35, height: 35 }}
              className={styles.link}
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
}
