import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../Resources/dataroomLogo.png";


export default function Hackathons() {
  return (
    <Jumbotron style={{ backgroundColor: "white" }}>
      <Container>
        <Row>
          <Col>
            <h1>HackTX 2019</h1>
            <p>
              In 24 hours, our
              team built{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://devpost.com/software/dataroom"
              >
                Dataroom
              </a>
              , an AR data visualization tool with Unity3D and Vuforia. Winner
              of Goldman Sachs Marquee API Challenge.
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/0ByJ_OuRVJPaaTG55YkxHSExLeFUxT1Njc1dYdExhSDd2NDlz/view?usp=sharing"
              >
                Demo
              </a>{" "}
            </p>
          </Col>
          <Col className="col-2">
            <img
              src={logo}
              alt="Dataroom Logo"
              style={{ display: "block", marginTop: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}
