import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FadeIn from "react-fade-in";
import SocialBar from "./SocialBar";
import myImage from "../Resources/me.JPG";
import Image from "react-bootstrap/Image";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function Headshot() {
    return (
        <Row>
            <Col
                style={{
                    backgroundColor: "",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                }}
            >
                <Row style={{ backgroundColor: "" }}>
                    <div
                        style={{
                            textAlign: "center",
                            color: "white",
                            width: "100%",
                        }}
                    >
                        <FadeIn delay={80}>
                            <h1 style={{ fontSize: 133 }}>Henry Liu</h1>
                            <h4 style={{ fontSize: 30 }}>
                                CS + Math @ UT Austin
                            </h4>
                            <div style={{ textAlign: "center" }}>
                                <SocialBar />
                            </div>
                        </FadeIn>
                    </div>
                </Row>
                <Row style={{ backgroundColor: "" }}>
                    <FadeIn delay={250}>
                        <Jumbotron
                            style={{
                                padding: 30,
                                // margin: "auto",
                                backgroundColor: "rgba(255,255,255,.8)",
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "10px",
                                    fontSize: "1.2rem",
                                }}
                            >
                                I'm a junior at UT Austin excited about backend SWE, distributed systems, and robotics!
                                More recently I have developed an interest in large-scale computation and parallel programs.
                            </p>
                        </Jumbotron>
                    </FadeIn>
                </Row>
            </Col>
            <Col
                className="col-1 d-none d-md-block d-lg-block d-xl-block"
                style={{ backgroundColor: "" }}
            />
            <Col
                style={{ backgroundColor: "" }}
                className="d-none d-md-block d-lg-block d-xl-block"
            >
                <FadeIn delay={230}>
                    <Image src={myImage} alt="My Picture" rounded responsive />
                </FadeIn>
            </Col>
        </Row>
    );
}
