import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../Resources/logo128.png";

export default function EasyCal() {
  return (
    <Jumbotron style={{ paddingTop: "", backgroundColor: "white" }}>
      <Container>
        <Row>
          <Col>
            <div style={{ display: "flex" }}>
              <h1>EasyCal</h1>
            </div>
            <p>
              A Google Chrome extension built with Javascript
              and React leveraging NLP to make saving calendar events easier.
              Highlight text and click the extension - EasyCal will
              predict the event title/dates for you and add it to your calendar.
            </p>
            <p style={{ fontSize: 14 }}>
              Currently in Google OAuth consent verification - users may be
              prompted with an unverified app warning when authorizing
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/easycal/pojjblkbbcpebnekchfocfdpihbdgkoj?hl=en"
              >
                Chrome Web Store
              </a>{" "}
              |{" "}
              <a target="_blank" rel="noopener noreferrer" href="/easycal">
                Homepage
              </a>{" "}
            </p>
          </Col>
          <Col className="col-2" style={{ textAlign: "center" }}>
            {/* <div style={{ display: "table-cell", verticalAlign: "middle", margin:"auto" }}> */}
            <img
              src={logo}
              alt="EasyCal Logo"
              style={{ display: "block", marginTop: "auto" }}
            />
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}
