import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function Research() {
  return (
    <Jumbotron style={{ backgroundColor: "white" }}>
      <Container>
        <h1>Research</h1>
        <p>
          I currently work with the Building-Wide Intelligence Group/Learning Agents Research Group
          at UT Austin under the supervision of Dr. Justin Hart. 
          Much of my work is hosted on private GitHub repos, please
          contact me directly if you would like to discuss. My current work
          investigates the use of machine learning and computer vision techniques to improve robot navigation.
          <br/>
          Publications:
          <ul>
            <li><a
                target="_blank"
                rel="noopener noreferrer"
                href="https://arxiv.org/pdf/2110.14742.pdf"
                >
                    D. Balaban, H. Jagannathan, H. Liu, and J. Hart. "Efficient Placard Discovery for Semantic Mapping During Frontier Exploration". AAAI Spring Symposium Series, 2021, Online.
                </a></li>
          </ul>
        </p>
      </Container>
    </Jumbotron>
  );
}
