import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./Components/Main";
import PrivacyPol from "./Components/Extension/PrivacyPol";
import ExtensionHome from "./Components/Extension/ExtensionHome";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import ParticlesBg from "particles-bg";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router";

ReactDOM.render(
  <Router>
    <Route exact path="/easycal">
      <ExtensionHome />
      <ParticlesBg type="cobweb" bg={true}></ParticlesBg>
    </Route>
    <Route exact path="/easycal/privacy-policy">
      <PrivacyPol />
    </Route>
    <Route exact path="/">
      <Main />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "200%",
          top: 0,
          left: 0,
          zIndex: -100,
          background:"rgb(3,0,46)",
          background:
            "linear-gradient(180deg, rgba(3,0,46,1) 10%, rgba(175,175,175,1) 50%, rgba(255,255,255,1) 80%)",
        }}
      >
        <ParticlesBg
          color={["#ffffff"]}
          num={200}
          type="cobweb"
          bg={true}
        ></ParticlesBg>
      </div>
    </Route>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();