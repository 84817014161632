import React from "react";
import Saver from "./Saver";
import EasyCal from "./EasyCal";
import Research from "./Research";
import Hackathons from "./Hackathons";
import PathTracer from "./PathTracer";

export default function Portfolio() {
  return (
    <>
      <Research />
      <PathTracer />
      <Saver />
      <EasyCal />
      <Hackathons />
      

    </>
  );
}
