import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../Resources/logo128.png";
import pic1 from "../../Resources/pic1.png";
import pic2 from "../../Resources/pic2.png";

export default function ExtensionHome() {
  return (
    <Container
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Jumbotron>
        <Container>
          <Row>
            <Col className="col-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/there-text-to-calendar-ev/pojjblkbbcpebnekchfocfdpihbdgkoj"
                style={{ textAlign: "center" }}
              >
                <div style={{ textAlign: "center" }}>
                  <img src={logo} alt="EasyCal Logo" />
                </div>
              </a>
            </Col>
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/there-text-to-calendar-ev/pojjblkbbcpebnekchfocfdpihbdgkoj"
              >
                <h1>EasyCal</h1>
              </a>
              <h5>Turn text into calendar events with one easy click</h5>
              <br />
              <Carousel interval={5000}>
                <Carousel.Item style={{ textAlign: "center" }}>
                  <img src={pic1} alt="demo pic 1" width="400" height="100%"></img>
                </Carousel.Item>
                <Carousel.Item style={{ textAlign: "center" }}>
                  <img src={pic2} alt="demo pic 2" width="400" height="100%"></img>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Row>
            <Col className="col-3" />
            <Col>
              <h6>How-To</h6>
              <p>
                1. Highlight event information with your cursor (ex. "Meeting
                tomorrow at noon")
              </p>
              <p> 2. Click the extension icon </p>{" "}
              <p>3. Adjust event name/date if needed </p>{" "}
              <p>
                4. Click "Add Event" and the event has been added to your Google
                Calendar!
              </p>
              <h6>Google Authorization</h6>
              <p>
                EasyCal must have access to your Google Calendar to add events. To grant access, you will be prompted with
                a consent screen the first time the extension is opened.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="col-10"></Col>
            <Col>
              <a href="/easycal/privacy-policy">Privacy Policy</a>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </Container>
  );
}
