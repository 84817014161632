import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import FadeIn from "react-fade-in";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import Portfolio from "./Portfolio/Portfolio";
import { animateScroll as scroll } from "react-scroll";
import Headshot from "./Headshot"

import styles from "./Main.module.css";

const scrollToRef = (ref) => {
  if (ref /* + other conditions */) {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export default function Main() {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <>
      <Container
        style={{
          marginTop: "10%",
          maxWidth: 1200,
        }}
      >
        <Row />
        <Headshot />
        <FadeIn delay={350}>
          <Row style={{ backgroundColor: "" }}>
            <div
              className={styles.downButton}
              style={{ textAlign: "center" }}
              onClick={executeScroll}
            >
              {/* <p style={{ fontSize: "1.2rem", lineHeight: "0" }}>About Me | Portfolio</p> */}
              <ArrowDropDownCircleIcon
                style={{
                  width: 45,
                  height: 45,
                }}
              />
            </div>
          </Row>
        </FadeIn>
        <Row>
          {/* <h1
            style={{
              fontSize: 80,
              marginTop: "7rem",
              marginBottom: "2rem",
              color: "black",
            }}
            ref={myRef}
          >
            About Me
          </h1> */}
        </Row>


        <Row>
          <h1
            style={{
              fontSize: 80,
              marginTop: "7rem",
              marginBottom: "2rem",
              // color: "white",
            }}
          >
            Portfolio
          </h1>
        </Row>
        <Row style={{ backgroundColor: "" }}>
          <div style={{ width: "100%" }}>
            <Portfolio />
          </div>
        </Row>
        <Row>
          <div
            style={{ marginTop: "4rem", width: "100%", textAlign: "center" }}
          >
            This website was built with React &#38; React Bootstrap and is fully
            responsive!
          </div>
        </Row>
      </Container>
    </>
  );
}
