import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dragon from "../../Resources/3dragon-30k.png";
import Image from "react-bootstrap/Image";

export default function PathTracer() {
  return (
    <Jumbotron style={{ paddingBottom: "0", backgroundColor: "white" }}>
      <Container>
        <Row>
          <Col>
            <div style={{ display: "flex" }}>
              <h1>CUDA Path Tracer</h1>
            </div>
            <p>
                <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://en.wikipedia.org/wiki/Path_tracing"
                >
                    Path tracer 
                </a> {" "}
                built in C++ to simulate global illumination in 3D scenes. 
              Uses CUDA to leverage GPU acceleration for several hundred times speedup vs. CPU implementation.
              <br/>
              Notable features include:
                <ul>
                <li>Support for diffuse, perfectly specular, and dielectric materials</li>
                <li>Optimized CUDA kernels</li>
                <li>Russian roulette path termination</li>
                <li>Cosine-weighted hemisphere sampling</li>
                </ul>
            </p>
            
          </Col>
          <Col className="col-4" style={{textAlign:"center"}}>
            {/* <div style={{ display: "table-cell", verticalAlign: "middle", margin:"auto" }}> */}
            <Image src={dragon} alt="3 Dragon" rounded responsive />
            <p>512x512, 30k samples - rendered in 10 minutes <a
            target="_blank"
            rel="noopener noreferrer"
            href={dragon}
            >
            Full Image
            </a></p>


          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}
