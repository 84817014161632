import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function Saver() {
  return (
    <Jumbotron style={{backgroundColor: "white" }}>
      <Container>
        <h1>Saver</h1>
        <p>
          Saver is a website designed to save you money on your next grocery
          trip. By scraping data from retailers such as Walmart, Target, and
          Kroger using Google's Puppeteer across hundreds of zip codes
          throughout the US, Saver can find the best deals - even for
          commodities.
        </p>
        <p>
          Built using React, Express, MongoDB, and Node. Check out the progress
          at{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://savr.dev">
            savr.dev
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/henryliu5/Saver/tree/master"
          >
            GitHub
          </a>{" "}
        </p>
      </Container>
    </Jumbotron>
  );
}
